@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap);
.wallet-adapter-button {
  background-color: #404144;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Inter', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
}

.wallet-adapter-button-trigger {
  background-color: #4e44ce;
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: white;
}

.wallet-adapter-button:not([disabled]):hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
}

.wallet-adapter-button[disabled] {
  background: #404144;
  color: #999;
  cursor: not-allowed;
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.wallet-adapter-button-end-icon {
  margin-left: 8px;
}

.wallet-adapter-button-start-icon {
  margin-right: 8px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  position: relative;
  display: inline-block;
}

.wallet-adapter-dropdown-list {
  position: absolute;
  z-index: 99;
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 10px;
  padding: 10px;
  top: 100%;
  right: 0;
  margin: 0;
  list-style: none;
  background: #2c2d30;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease, visibility 200ms, -webkit-transform 200ms ease;
  transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
  transition: opacity 200ms ease, transform 200ms ease, visibility 200ms, -webkit-transform 200ms ease;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  background: #404144;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  height: 37px;
  color: white;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
}

.wallet-adapter-modal-collapse-button svg {
  align-self: center;
  fill: #999;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: -webkit-transform ease-in 150ms;
  transition: transform ease-in 150ms;
  transition: transform ease-in 150ms, -webkit-transform ease-in 150ms;
}

.wallet-adapter-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity linear 150ms;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 6px;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: white;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill 200ms ease 0s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-logo {
  max-width: 100%;
}

.wallet-adapter-modal-logo-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background: #141515;
  margin-bottom: 10px;
  padding: 12px;
}

.wallet-adapter-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wallet-adapter-modal-container {
  display: flex;
  margin: 3rem;
  min-height: calc(100vh - 6rem); /* 100vh - 2 * margin */
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .wallet-adapter-modal-container {
    margin: 1rem;
    min-height: calc(100vh - 2rem); /* 100vh - 2 * margin */
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1050;
  max-width: 400px;
  border-radius: 10px;
  background: #2c2d30;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  font-family: 'Inter', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  padding: 40px 20px 20px;
  flex: 1 1;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-wrapper-no-logo {
  padding-top: 30px;
}

.wallet-adapter-modal-title {
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  margin-bottom: 27px;
  margin-top: 0;
  width: 100%;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 374px) {
  .wallet-adapter-modal-title {
    font-size: 26px;
  }
}

.wallet-adapter-modal-list {
  margin: 0 0 12px;
  padding: 0;
  width: 100%;
  list-style: none;
}

.wallet-adapter-modal-list li:not(:first-of-type) {
  margin-top: 12px;
}

@font-face{
  font-family:"revicons";

  fallback:fallback;

  src:url(/static/media/revicons.e8746a62.woff) format('woff'),url(/static/media/revicons.57fd05d4.ttf) format('ttf'),url(/static/media/revicons.a77de540.eot) format('ttf')
}

.react-multi-carousel-list{
  display:flex;
  align-items:center;
  overflow:hidden;
  position:relative
}

.react-multi-carousel-track{
  list-style:none;
  padding:0;
  margin:0;
  display:flex;
  flex-direction:row;
  position:relative;
  -webkit-transform-style:preserve-3d;
  transform-style:preserve-3d;
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  will-change:transform,transition
}

.react-multiple-carousel__arrow{
  position:absolute;
  outline:0;
  transition:all .5s;
  border-radius:35px;
  z-index:1000;
  border:0;
  background:rgba(0,0,0,0.5);
  min-width:43px;
  min-height:43px;
  opacity:1;
  cursor:pointer
}

.react-multiple-carousel__arrow:hover{
  background:rgba(0,0,0,0.8)
}

.react-multiple-carousel__arrow::before{
  font-size:20px;
  color:#fff;
  display:block;
  font-family:revicons;
  text-align:center;
  z-index:2;
  position:relative
}

.react-multiple-carousel__arrow:disabled{
  cursor:default;
  background:rgba(0,0,0,0.5)
}

.react-multiple-carousel__arrow--left{
  left:calc(4% + 1px)
}

.react-multiple-carousel__arrow--left::before{
  content:"\e824"
}

.react-multiple-carousel__arrow--right{
  right:calc(4% + 1px)
}

.react-multiple-carousel__arrow--right::before{
  content:"\e825"
}

.react-multi-carousel-dot-list{
  position:absolute;
  bottom:0;
  display:flex;
  left:0;
  right:0;
  justify-content:center;
  margin:auto;
  padding:0;
  margin:0;
  list-style:none;
  text-align:center
}

.react-multi-carousel-dot button{
  display:inline-block;
  width:12px;
  height:12px;
  border-radius:50%;
  opacity:1;
  padding:5px 5px 5px 5px;
  box-shadow:none;
  transition:background .5s;
  border-width:2px;
  border-style:solid;
  border-color:grey;
  padding:0;
  margin:0;
  margin-right:6px;
  outline:0;
  cursor:pointer
}

.react-multi-carousel-dot button:hover:active{
  background:#080808
}

.react-multi-carousel-dot--active button{
  background:#080808
}

.react-multi-carousel-item{
  -webkit-transform-style:preserve-3d;
  transform-style:preserve-3d;
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden
}

@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){
  .react-multi-carousel-item{
    flex-shrink:0 !important
  }

  .react-multi-carousel-track{
    overflow:visible !important
  }
}
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-clock {
  display: block;
  position: relative;
}

.react-clock,
.react-clock *,
.react-clock *:before,
.react-clock *:after {
  box-sizing: border-box;
}

.react-clock__face {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid black;
  border-radius: 50%;
}

.react-clock__hand {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}

.react-clock__hand__body {
  position: absolute;
  background-color: black;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.react-clock__mark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}

.react-clock__mark__body {
  position: absolute;
  background-color: black;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.react-clock__mark__number {
  position: absolute;
  left: -40px;
  width: 80px;
  text-align: center;
}

.react-clock__second-hand__body {
  background-color: red;
}

.react-datetime-picker {
  display: inline-flex;
  position: relative;
}

.react-datetime-picker,
.react-datetime-picker *,
.react-datetime-picker *:before,
.react-datetime-picker *:after {
  box-sizing: border-box;
}

.react-datetime-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
}

.react-datetime-picker__inputGroup {
  min-width: calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
}

.react-datetime-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-datetime-picker__inputGroup__input {
  min-width: 0.54em;
  height: calc(100% - 2px);
  position: relative;
  padding: 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}

.react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datetime-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-datetime-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px +  0.54em);
}

.react-datetime-picker__inputGroup__amPm {
  font: inherit;
  -moz-appearance: menulist;
}

.react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-datetime-picker__button:enabled {
  cursor: pointer;
}

.react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
  stroke: #0078d7;
}

.react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
  stroke: #6d6d6d;
}

.react-datetime-picker__button svg {
  display: inherit;
}

.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  z-index: 1;
}

.react-datetime-picker__calendar--closed,
.react-datetime-picker__clock--closed {
  display: none;
}

.react-datetime-picker__calendar {
  width: 350px;
  max-width: 100vw;
}

.react-datetime-picker__calendar .react-calendar {
  border-width: thin;
}

.react-datetime-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
}

